import React from "react";
import mobiusLogo from "../../images/icon-mobius-logo.svg";
import seperator from "../../images/icon-seperator.png";
import "./Header.scss";




const Header = (props) => {
	
	const redirectSection=(id)=>{
		var element = document.getElementById(id);
        var headerOffset = 45;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth"
	   });
	}
	
	return (
		
			<div className='header-container'>
				<div className='header-left-side' >
					<div className='mobius-logo'>
						<img src={mobiusLogo} alt='mobius logo' onClick={()=>redirectSection("top")} />
					</div>
					<img src={seperator} alt='|' />
					<div className='state-info'>DELOITTE</div>
				</div>
				
					<div className='header-right-side'>
				     <nav onClick={()=>redirectSection("products")}>Our Products</nav>
					 <nav onClick={()=>redirectSection("highlights")}>Benefits</nav>
					 <nav className="request-demo-text" onClick={()=>redirectSection("demo")}>Request Demo</nav>
					</div>
				
			</div>
			
	
	);
};
export default Header;
