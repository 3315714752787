import React from "react";
import arrowDown from "../../images/arrow 1.png";
import awsLogo from "../../images/aws.svg";
import gcpLogo from "../../images/gcp.svg";
import "./Dropdown.scss";
const Dropdown = (props) => {
	const {
		label,
		isListOpen,
		setIsListOpen,
		title,
		list,
		selectedItem,
		setSelectedItem,
		className,
		showServiceProviderImg,
	} = props;


	const handleSelect = (item) =>{
        if(selectedItem.id !== item.id){
			setSelectedItem(item.id);
			setIsListOpen(!isListOpen);
		} 
	}
	return (
		<div className={`dropdown ${className}`}>
			<div className='dd-label'>
				<span className='dd-label-text'>{label}</span>
				<div className='dd-info-icon'></div>
			</div>
			<div className='dd-wrapper'>
				<button
					type='button'
					className='dd-header'
					onClick={() => setIsListOpen(!isListOpen)}
					onKeyDown={(e) => {
						if (e.key === "enter") {
							setIsListOpen(!isListOpen);
						}
					}}>
					{showServiceProviderImg && (
						<img
							className='service-provider-logo'
							src={
								selectedItem?.cloudServiceProvider === "aws" ? awsLogo : gcpLogo
							}
							alt='serviceProviderLogo'
						/>
					)}
					<div className='dd-header-title'>{title}</div>
					{isListOpen ? (
						<img className='dd-open' src={arrowDown} alt='open' />
					) : (
						<img className='dd-close' src={arrowDown} alt='close' />
					)}
				</button>
			</div>
			{isListOpen && (
				<div role='list' className='dd-list'>
					{list.map((item) => (
						<button
							type='button'
							className='dd-list-item'
							key={item.id}
							onClick={() => {
								handleSelect(item)
							}}>
							{showServiceProviderImg && (
								<img
									className='service-provider-logo'
									src={item.cloudServiceProvider === "aws" ? awsLogo : gcpLogo}
									alt='serviceProviderLogo'
								/>
							)}
							{item.title}
						</button>
					))}
				</div>
			)}
		</div>
	);
};
export default Dropdown;
