import React from "react";
import './App.scss';
import Landing from './pages/landing/Landing';


function App() {
  return (
    <>
    <Landing />
    </>
  );
}

export default App;
