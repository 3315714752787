import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Input from "../../components/input-component/Input";
import Button from "../../components/button-component/Button";
import "./Landing.scss";
import Dropdown from "../../components/dropdown-component/Dropdown";
import Header from "../../components/header-component/Header";
import Footer from "../../components/footer-component/Footer";
import video from "../../assets/video/Mobius_Internal_SFX (1).mp4";
import ourMission from "../../images/our-mission.svg";
import logo from "../../images/logoicon.svg";
import videoThumbnail from "../../images/video-thumbnail.png";
import playicon from "../../images/play-icon.svg";
import { dropdownOptions, products } from "../../constants/content";
import Keyhighlights from "../../components/sections/Keyhighlights";
import one from "../../images/spirals/one.svg";
import two from "../../images/spirals/two.svg";
import three from "../../images/spirals/three.svg";
import four from "../../images/spirals/four.svg";
import five from "../../images/spirals/five.svg";
import six from "../../images/spirals/six.svg";
import Popup from "../../components/popup-component/Popup";

function Landing() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [note, setNote] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [visible, setVisble] = useState(true);
  const [type, setType] = useState();
  const [msg, setMsg] = useState();

  const routeChange = (path) => {
    window.open(path, "_blank").focus();
  };

  const getSelectedItem = (id) => {
    return dropdownOptions.find((entry) => entry.id === id);
  };
  useEffect(() => {
    var element = document.getElementById("top");
    var headerOffset = 45;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
    });
  }, []);

  const resetStates = () => {
    setNote("");
    setName("");
    setEmail("");
    setSelectedItem("");
    setOrganization("");
  };

  const sendEmail = (url, params) => {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        Accept: "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((res) => {
        if (res.status === 200) {
          setType("success");
          setMsg(
            "Thank You for your interest! Our team will reach out to you soon"
          );
          setVisble(true);
          resetStates();
        } else {
          setMsg(
            "There was an error while sending your request! Please try again"
          );
          setType("Failure");
          resetStates();
        }
      })
      .catch((err) => {});
  };

  const sendDemoRequest = (event) => {
    event.preventDefault();
    const selectedProduct = getSelectedItem(selectedItem).title;
    const mailBody = `
    Name: ${name}, 
    Organization: ${organization}
    Product: ${selectedProduct}
    Email: ${email}.
    Note: ${note}`;

    const params = {
      subject: "mObius Demo Requested",
      body: mailBody,
    };
    const url =
      "https://nucleus-engine.aistudio-deloitte.com/user/send_inquiry_mail";
    sendEmail(url, params);
  };

  return (
    <>
      <div className="landing-page-container">
        <Header />
        <section className="main-section" id="top">
          <div className="main-section-spiral">
            <img src={one} alt="" className="one-img" />
            <img src={two} alt="" className="two-img" />
            <img src={three} alt="" className="three-img" />
            <img src={four} alt="" className="four-img" />
            <img src={five} alt="" className="five-img" />
            <img src={six} alt="" className="six-img" />
          </div>
          <h1 className="main-section-title">
            Explore the Possibilities with AI
          </h1>
          <img src={logo} alt="" className="mobius-logo" />
          {/* <p className="main-section-text">
                A tool for all kinds of machine learning related business needs.
                Start today with ready-to-use ML solutions or create your own
                using our simple no-code platform.
              </p> */}
        </section>
        <section className="video-section">
          <div className="video-container">
            <ReactPlayer
              url={video}
              className="react-video-player"
              playing="true"
              controls="true"
              playIcon={<img src={playicon} alt="" />}
              light={videoThumbnail}
            />
          </div>
        </section>
        <section className="our-mission-section">
          <div className="our-mission-card">
            <h1 className="our-mission-header">Our mission</h1>
            <p className="our-mission-text">
              We aspire to Democratize AI so that everyone in the value chain
              can start leveraging the power of AI/ML and bring ML-based
              solutions from an idea to market in the shortest possible time
              without compromising on data-security.
            </p>
            <img src={ourMission} alt="our-misson" />
          </div>
        </section>
        <section className="products" id="products">
          <p className="products-text">Our Products</p>
          <h1 className="products-title">What does mObius offer?</h1>
          <div className="products-tabs-wrapper">
            {products.map((product) => (
              <div className="tab-wrapper">
                <div className="left-tab">
                  {product.productSummary?.map((item, id) => (
                    <div className="card">
                      <h3 className="card-id">0{id + 1}</h3>
                      <h1>{item.title}</h1>
                      <h3>{item.subtext}</h3>
                    </div>
                  ))}
                </div>
                <div className="right-tab">
                  <p className="right-tab-product">{product.title}</p>
                  <h1 className="right-tab-title">{product.desc}</h1>
                  <p className="right-tab-subtext">{product.subtext}</p>
                  <Button
                    disabled={!product.ctaEnable}
                    onClick={() => routeChange(product.productLink)}
                  >
                    {product.ctaText}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </section>
        <Keyhighlights />
        <section className="request-demo-section" id="demo">
          {visible && (
            <Popup
              visible={visible}
              popupType={type}
              message={msg}
              setVisible={setVisble}
            />
          )}
          <div className="request-demo-typo">
            <p className="request-demo-subtitle">Request Demo</p>
            <h1 className="request-demo-title">Solution for all ML problems</h1>
          </div>
          <div className="request-demo-form">
            <div className="request-container-form">
              <form>
                <Input
                  label="Name"
                  name="name"
                  value={name}
                  className="request-container-input"
                  labelClassName="signin_page-container-form-label"
                  onChange={({ value }) => setName(value)}
                  required
                  theme="dark"
                />
                <Dropdown
                  label="Product"
                  className="request-dd"
                  title={
                    selectedItem
                      ? getSelectedItem(selectedItem).title
                      : "Select"
                  }
                  list={dropdownOptions}
                  isListOpen={isListOpen}
                  setIsListOpen={setIsListOpen}
                  setSelectedItem={setSelectedItem}
                  selectedItem={selectedItem}
                />
                <Input
                  label="Organization"
                  name="organization"
                  value={organization}
                  className="request-container-input"
                  labelClassName="signin_page-container-form-label"
                  onChange={({ value }) => setOrganization(value)}
                  required
                  theme="dark"
                />
                <Input
                  label="Email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={({ value }) => setEmail(value)}
                  className="signin_page-container-input"
                  labelClassName="signin_page-container-form-label"
                  required
                  theme="dark"
                />
                <Input
                  label="Any note for us? (Optional)"
                  name="email"
                  type="textarea"
                  multi="true"
                  className="request-container-area"
                  theme="dark"
                  value={note}
                  onChange={({ value }) => setNote(value)}
                />
              </form>
              <Button
                type="submit"
                className="request-container-button"
                onClick={(event) => sendDemoRequest(event)}
                disabled={!(name && email && selectedItem && organization)}
              >
                Submit
              </Button>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Landing;
