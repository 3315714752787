import React, { useEffect } from "react";
import popupSuccess from "../../images/popup-success.svg";
import popupWarning from "../../images/popup-warning.svg";
import popupError from "../../images/popup-error.svg";
import closeWhite from "../../images/close-white.svg";
import "./popup.scss";

const Popup = ({ visible, popupType, message, setVisible }) => {
 
  useEffect(() => {
    const timeId = setTimeout(() => {
      setVisible(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  }, [visible, setVisible]);
  
  const popupIcon = (Type) => {
    switch (Type) {
      case "success":
        return <img src={popupSuccess} alt="successIcon" />;
      case "warning":
        return <img src={popupWarning} alt="warningIcon" />;
      case "error":
        return <img src={popupError} alt="errorIcon" />;
      default:
        return;
    }
  };
  if (visible) {
    return (
      <div className={`popup-container type-${popupType}`}>
        {popupIcon(popupType)}
        <span>{message}</span>
        <img
          onClick={() => setVisible(false)}
          className="close-icon"
          src={closeWhite}
          alt="closeIcon"
        />
      </div>
    );
  }
  return null;
};

export default Popup;
