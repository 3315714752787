import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-dark">
        <span className="copyright-year">
          @Copyright {new Date().getFullYear()}
        </span>
        <span className="studio-info">DELOITTE</span>
      </div>
    </div>
  );
};

export default Footer;
