import React, { useLayoutEffect, useRef, useState } from "react";
import "./Keyhighlights.scss";
import modelImg from "../../images/details_page.png";
import modelMonitor from "../../images/model-monitoring.png";
import nebulaImg from "../../images/nebula-img.png";
import pipelineImg from "../../images/pipelines.png";

const content = [
  {
    title: "No Code Platform",
    text: "Get to solutions faster using the interactive UI, without any technical pre-requisites.",
    image: modelImg,
  },
  {
    title: "End-to-End ML Model Development & Monitoring",
    text: " End to End AI process automation for businesses, to enhanace productivity and make ML-operations simple",
    image: modelMonitor,
  },
  {
    title: "Wide Use-Case Support",
    text: "mObius supports a wide range of ML models catering related to common real-life use-cases",
    image: nebulaImg,
  },
  {
    title: "Secure & Cloud-Agnostic",
    text: "With mObius, data never leaves your ecosystem - safeguarding PII/PHI.",
    image: pipelineImg,
  },
];

function Keyhighlights(props) {
  const highlightRef = useRef();
  const [top, setTop] = useState([]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const cards = document.getElementsByClassName("key-highlights-card");
      const topArr = [];
      if (cards.length) {
        for (let index = 0; index < cards.length; index++) {
          topArr.push(cards[index].getBoundingClientRect().top);
        }
        setTop(topArr);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getStyles = (index) => {
    const css = {};
    // if (index > 0 && top[index] < 800) {
    //   css.opacity = (1264 - top[index]) / 1000;
    // }
    if (top[index + 1] === 320) {
      css.opacity = 0;
    }
    if (index === content.length - 1 && top[content.length - 1] <= 320) {
      css.opacity = 1;
    } else if (index < content.length - 1 && top[content.length - 1] < 320) {
      css.opacity = 0;
    }
    return css;
  };
  return (
    <section
      className="key-highlights-section"
      id="highlights"
      ref={highlightRef}
    >
      <div
        className="key-highlights-text-container"
        style={{
          position: `${top[content.length - 1] < 264 ? "relative" : "sticky"}`,
        }}
      >
        <h2 className="section-title">Key Highlights</h2>
        <h1 className="key-highlights-header">Why mobius?</h1>
      </div>
      <div className="key-highlights">
        {content.map((data, index) => (
          <div
            className={`key-highlights-card  highlight-card-${index}`}
            style={{ ...getStyles(index) }}
          >
            <img className="model-img" src={data.image} alt="" />
            <div className="key-highlights-subtext">
              <h3 className="first-title">{data.title}</h3>
              <p className="first-text">{data.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Keyhighlights;
