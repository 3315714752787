import React from "react";
import classnames from "classnames";
import * as styles from "./Button.module.scss"

export const ButtonType = {
  BUTTON: "button",
  RESET: "reset",
  SUBMIT: "submit",
};

export const ButtonTheme = {
  DEFAULT: "default",
  GHOST: "ghost",
  LINK: "link",
};

export const ButtonSize = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

const Button = (props, ref) => {
  const {
    type,
    onClick,
    children,
    theme = "default",
    size = "medium",
    className,
    disabled,
  } = props;
  const classProps = classnames(
    styles.button,
    styles[theme],
    styles[size],
    {
      [styles.disabled]: disabled,
    },
    className
  );

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classProps}
    >
      {children}
    </button>
  );
};
export default Button;
