export const products = [
  {
    title: "Nebula",
    desc: "Ready-to-use ML solutions",
    subtext:
      "Get started today with industry-grade ready-to-use ML solutions using mObius' microservices.",
    ctaEnable: true,
    ctaText: "Get Started",
    productLink: "https://mobius-nebula.aistudio-deloitte.com",
    productSummary: [
      {
        title: "Explore Use-cases",
        subtext:
          "Explore the marketplace and assess ML solutions based on your requirements.",
      },
      {
        title: "Request Demo",
        subtext:
          "Raise a request to the mObius team on how to use the available model for the problem.",
      },
      {
        title: "Integrate & Monitor",
        subtext:
          "Integrate the solution with your existing application and monitor it’s performance.",
      },
    ],
  },
  {
    title: "Nucleus",
    desc: "Create your own ML solutions",
    subtext:
      "Use mObius as a no-code tool to build ML models and bring ideas to market in just a few clicks, completing days worth of work in a few hours.",
    ctaEnable: true,
    ctaText: "Get Started",
    productLink: "https://dev-nucleus.aistudio-deloitte.com",
    productSummary: [
      {
        title: "Define Training Data",
        subtext:
          "Select your raw-data to be used for training the model. Label the data for training.",
      },
      {
        title: "Train & Deploy Model",
        subtext:
          "With a few clicks, train your ML model, access its accuracy and deploy it.",
      },
      {
        title: "Integrate, Monitor & Improve",
        subtext:
          "Integrate the ML model with your existing application. Monitor its performance and enhance the solution over time.",
      },
    ],
  },
  {
    title: "Meteor",
    desc: "Deploy & scale custom-built models",
    subtext:
      "Build customized ML models. Reuse and evolve them by fine-tuning on the dataset relevant for a problem.",
    ctaEnable: false,
    ctaText: "Coming Soon",
    productLink: "",
    productSummary: [
      {
        title: "Build customized ML models",
        subtext:
          "Empower yourself to build custom ML Solutions using a No-code tool not provided out-of-the-box by any cloud providers. ",
      },
      {
        title: "Fine-tune existing solutions",
        subtext:
          "Plug-and-play, retrain and improve baseline models based on the requirements.",
      },
      {
        title: "ML Workflows",
        subtext:
          "Using our no-code service, create an end-to-end solution requiring multiple ML operations.",
      },
    ],
  },
];

export const dropdownOptions = [
  {
    id: 1,
    title: "Mobius Nebula",
    key: "mobius_nebula",
  },
  {
    id: 2,
    title: "Mobius Nucleus",
    key: "mobius_nucleus",
  },
  {
    id: 3,
    title: "Mobius Meteor",
    key: "mobius_meteor",
  },
];
